.mainImg {
  position: relative;
  height: 150px;
  margin-bottom: 30px;
  overflow: visible;
  z-index: 0;
  @media @small-up {
    margin-bottom: 65px;
    height: 260px;
  }
  @media @xsmall {
    margin-bottom: 80px;
  }
  .is--service & {
    @media @small-up {
      margin-bottom: 0;
    }
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: calc(50vw + 144px);
    height: 150px;
    background: rgba(@primary, 10%);
    z-index: -1;
    @media @small-up {
      top: 0;
      width: calc(50vw + 370px);
      height: 260px;
    }
    @media @xsmall {
      bottom: -36px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: calc(50vw + 160px);
    height: 150px;
    background: center right/auto 100% no-repeat;
    background-image: url('/asset/img/content_bg_sp@2x.png');
    background-color: @primary;
    z-index: -1;
    @media @small-up {
      top: -64px;
      max-width: 85vw;
      width: calc(50vw + 362px);
      height: 260px;
      background-image: url('/asset/img/content_bg@2x.png');
    }
    @media @xsmall {
      max-width: calc(100% - 20px);
    }
  }
  &Inner {
    position: relative;
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    width: calc(50vw + 160px);
    height: 150px;
    margin-left: auto;
    padding: 5rem 1rem 2rem 3rem;
    @media @small-up {
      width: calc(50vw + 362px);
      height: 260px;
      padding: 2.6rem 0 0 7.8rem;
    }
    @media @xsmall {
      max-width: calc(100% - 20px);
    }
  }
  h2 {
    position: relative;
    margin-bottom: 0;
    font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
    font-size: 3.8rem;
    color: @white;
    letter-spacing: .09em;
    @media @xsmall {
      font-size: 2.4rem;
    }
  }

  .breadcrumbs {
    margin: 2rem 0 0;
    @media @xsmall {
      margin-top: 1rem;
    }
    ol {
      padding: 0;
      li {
        color: @white;
        span {
          font-size: 1.3rem !important;
          letter-spacing: .1em;
          @media @xsmall {
            font-size: 1.2rem !important;
          }
        }
      }
      li a {
        &:hover, &:focus {
          color: rgba(@white, 60%) !important;
          span {
            color: rgba(@white, 60%) !important;
          }
        }
        &:after {
          color: @white;
        }
      }
      li:last-child a:after {
        display: none;
      }
    }

  }
}