@keyframes up_down_line {
  0%   { transform: translateX(0); }
  50%  { transform: translateX(12px); }
}

footer{
  background-color: @dark;
  color: @white;
}

.p-pagetop{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -24px;
  bottom: 100px;
  width: 151px;
  font-size: 1.6rem;
  font-family: 'Cantarell','Roboto', @sans-serif-font-family;
  color: @primary;
  text-decoration: none;
  letter-spacing: 0.12em;
  transform: rotate(-90deg);
  transform-origin: bottom;
  @media @xsmall {
    display: none;
  }
  &:after{
    content: '';
    display: block;
    margin-left: 1em;
    width: 36px;
    height: 1px;
    background: @primary;
  }
  &:hover, &:focus {
    text-decoration: none !important;
    color: @primary !important;
    span {
      text-decoration: none !important;
    }
    &:after{
      animation: up_down_line .9s infinite ease-in-out;
    }
  }
  &__wrap{
    width: 100%;
    position: fixed;
    position: sticky;
    bottom: 0;
    z-index: 10;
    .transition(all @transition-fast ease-in-out);
    &.hidden{
      .translateY(200px);
      opacity: 0;
    }
  }
}



.p-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5rem 0 3.8rem;
  @media @small-up {
    padding: 10rem 3rem 3.8rem;
  }
  &__inner {
    display: flex;
    width: 100%;
    @media @small-up {
      justify-content: flex-start;
      max-width: 100%;
      width: 1100px;
      margin: 0 auto;
    }
    @media @xsmall {
      flex-direction: column;
    }
  }
  &__address {
    @media @small-up {
      margin-right: auto;
    }
    @media @xsmall {
      text-align: center;
    }
    address {
      color: @white;
      font-style: normal;
      @media @small-up {
        text-align: left;
      }
      p {
        margin: 0;
        line-height: 1.8;
        color: inherit;
        @media @small-up {
          line-height: 2;
        }
        .visible-xsmall {
          @media @xsmall {
            display: inline !important;
          }
        }
      }
    }
    .btnwrap {
      margin-top: .5em;
      @media @small-up {
        margin-top: 1em;
        text-align: left;
      }
      a.button-text {
        height: auto;
        padding: 0;
        color: @white;
        font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
        font-size: 1.6rem;
        letter-spacing: .094em;
        font-weight: @medium-font-weight;
        @media @xsmall {
          font-size: 1.1rem;
        }
        i.fa {
          margin-right: 0.5em;
        }
        &:hover, &:focus {
          text-decoration: none;
          color: rgba(@white, 60%);
        }
      }
    }
  }
  &__logo{
    .hoverOpacity;
    display: block;
    margin-bottom: 2.5rem;
    color: @white;
    @media @small-up {
      margin-bottom: 1rem;
    }
  }
  &__navWrap {
    display: flex;
    @media @small-up {
      margin: 0 auto;
    }
    @media @xsmall {
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      order: 3;
    }
  }
  &__nav{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0em;
    @media @small-up {
      margin-left: 4rem;
      margin-right: 4rem;
    }
    @media @xsmall {
      width: 100%;
    }
    &.is-subnav{
      @media @xsmall {
        padding-top: 0.5em;
      }
      a {
        font-weight: @regular-font-weight;
        letter-spacing: .1em;
        @media @xsmall {
          padding-top: 0.5em;
          padding-bottom: 0;
          line-height: 1.8;
          border: none !important;
        }
      }
      a:after{
        background-color: fade( @white, 60% );
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
    a{
      position: relative;
      display: inline-block;
      color: inherit;
      font-size: 1.4rem;
      letter-spacing: .16em;
      font-weight: @bold-font-weight;
      margin: .5em 1em;
      line-height: 1.8;
      vertical-align: middle;
      @media @xsmall {
        display: block;
        width: 100%;
        margin: 0;
        padding: .75em 2.5rem;
        border: solid rgba(@white, 20%);
        border-width: 1px 0 0 0;
        text-align: left;
      }
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        border-width: 1px 0 1px 0;
      }
      &:hover, &:focus{
        text-decoration: none;
        color: shade( @white, 20%);
      }
    }
  }

  &__copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 8rem 3rem 0;
    color: rgba(@white, 50%);
    .login,.copyright {
      a,a:visited{
        color: inherit;
      }
    }
    .login{
      margin-top: 0.5em;
      margin-bottom: 0;
    }
    .copyright{
      margin-bottom: 0.5em;
    }
  }
}

.p-footer-contact{
  @media @small-up {
    margin-left: auto;
  }
  @media @xsmall {
    order: 2;
    text-align: center;
  }
  &__inner{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    @media @xsmall {
      align-items: center;
      justify-content: center;
      padding-top: 2.5rem;
      padding-bottom: 4.5rem;
    }
  }
  &__title{
    position: relative;
    padding-bottom: 0.3em;
    font-family: 'Roboto', sans-serif;
    font-weight: @bold-font-weight;
    color: @dark-grey;
    font-size: 2.7rem;
    letter-spacing: 0.12em;
    text-indent: 0.12em;
    text-align: center;
    &:after{
      position: absolute;
      top: 100%; left: calc( 50% - 0.5em );
      height: 2px; width: 1em;
      content: '';
      background-color: @dark-grey;
      @media @xsmall {
        left: calc( 50% - 0.5em );
      }
    }
  }
  &__telbox {
    color: @white;
    @media @small-up {
      padding-left: 35px;
    }

    .tel {
      .hoverOpacity();
      display: block;
      font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
      font-size: 2.8rem;
      letter-spacing: 0;
      color: inherit;
      font-weight: @regular-font-weight;
      white-space: nowrap;
      line-height: 1.4;

      @media @small-up {
        font-weight: @regular-font-weight;
        text-indent: -35px;
      }

      &:before {
        content: '\f095';
        display: inline-block;
        margin-right: 0;
        font-family: 'FontAwesome';
        font-size: 2.6rem;
        text-indent: 0;
        vertical-align: -0.05em;
        letter-spacing: 0;
        @media @xsmall {
          font-size: 2rem;
        }
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: tint(@white, 10%);
      }
    }

    .opentime {
      margin-top: 0.8rem;
      font-size: 1.2rem;
      letter-spacing: .16em;
      color: @white;
      @media @xsmall {
        text-align: center;
        text-indent: .16em;
      }
    }

    p {
      color: inherit;
      margin: 0 0 0.7em;
      text-align: left;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__btnwrap{
    margin-top: 3rem;
    .button{
      max-width: 100%;
      width: 260px;
      height: 5rem;
      background-color: transparent;
      font-size: 1.3rem;
      font-weight: @bold-font-weight;
      .icon {
        margin-right: 1em;
        fill: @white;
      }
      &:hover:not(:disabled),&:focus:not(:disabled){
        background-color: @secondary;
        border-color: @secondary;
      }
    }
  }
}