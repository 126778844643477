.is--service {


  #main>.element_detail .entry {
    overflow: visible;
  }

  .detailfooter {
    display: none;
  }

  &.is--list {
    // 1カラム化
    .hybs-one-column(100%);
    font-feature-settings: "palt";
    #allbox {
      overflow: hidden;
    }
    #main>.element_list {
      .list_imgflo.contents.img-list4 {
        margin: 0 auto 10rem;
        flex-direction: column !important;
        @media @xsmall {
          padding: 0 2rem;
        }
      }
    }
  }

  &.is--detail {
    .hybs-one-column(1000px, 20px);

    figure.imgbox {
      display: none;
    }
  }
}

/*共通*/
.service-contact {
  font-feature-settings: "palt";
  margin: 0 auto 8rem;
  @media @small-up {
    margin: 0 auto 11rem;
  }
  @media @xsmall {
    width: 100%;
    padding: 0 2rem;
  }
  &__inner {
    max-width: 100%;
    width: 960px;
    margin: 0 auto;
    padding: 4.6rem 10rem;
    background : linear-gradient(60deg, @dark 5%, @primary 40%, #3da1d9 75%, #6bd0c9 100%);
    @media @xsmall {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      padding: 3.4rem 3.4rem;
    }
  }

  &__titleWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 2.8rem;
    border-bottom: 1px solid rgba(@white, 30%);
    @media @xsmall {
      justify-content: center;
      flex-direction: column;
    }
  }
  &__title {
    margin-bottom: 0;
    color: @white;
    font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
    font-size: 3.3rem;
    font-weight: @bold-font-weight;
    letter-spacing: .094em;
    line-height: 1;
    @media @xsmall {
      margin-bottom: 1.5rem;
      font-size: 2.2rem;
      letter-spacing: .075em;
    }
  }
  &__lead {
    font-size: 1.2rem;
    color: @white;
    letter-spacing: .15em;
    @media @small-up {
      font-size: 1.3rem;
      padding-left: 2.5rem;
    }
    @media @xsmall {
      text-align: center;
    }
  }

  &__body {
    padding: 1.4rem 0 0;
    @media @small-up {
      display: flex;
      justify-content: center;
      padding: 2.2rem 2rem 0;
    }
  }
  &__telbox {
    color: @white;
    @media @small-up {
      padding-left: 35px;
      margin-right: 2.5rem;
    }
    @media @xsmall {
      text-align: center;
    }

    .tel {
      .hoverOpacity();
      display: block;
      font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
      font-size: 2.6rem;
      letter-spacing: 0;
      color: inherit;
      font-weight: @regular-font-weight;
      white-space: nowrap;
      line-height: 1;
      @media @small-up {
        font-weight: @regular-font-weight;
        font-size: 3.7rem;
        text-indent: -35px;
      }

      &:before {
        content: '\f095';
        display: inline-block;
        margin-right: 7px;
        font-family: 'FontAwesome';
        font-size: 2.8rem;
        text-indent: 0;
        vertical-align: -0.05em;
        letter-spacing: 0;
        @media @xsmall {
          font-size: 2rem;
          margin-right: 5px;
        }
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: tint(@white, 10%);
      }
    }

    .opentime {
      margin-top: 0.8rem;
      font-size: 1.3rem;
      letter-spacing: .16em;
      color: @white;
      @media @xsmall {
        font-size: 1.2rem;
        text-align: center;
      }
    }

    p {
      color: inherit;
      margin: 0 0 0.7em;
      text-align: left;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__btnwrap{
    @media @small-up {
      margin-left: 2.3rem;
    }
    @media @xsmall {
      margin-top: 2.1rem;
      text-align: center;
    }
    .button{
      max-width: 100%;
      width: 250px;
      height: 5rem;
      background-color: transparent;
      font-size: 1.3rem;
      font-weight: @bold-font-weight;
      @media @small-up {
        width: 330px;
        height: 6rem;
      }
      @media @xsmall {
        margin: 0 auto;
      }
      .icon {
        margin-right: 1em;
        fill: @white;
      }
      &:hover:not(:disabled),&:focus:not(:disabled){
        border-color: @secondary;
        background-color: @secondary;
      }
    }
  }
}

/*一覧*/
.service-head {
  position: relative;
  font-feature-settings: "palt";
  @media @small-up {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 791px;
    margin: -16px auto 0;
  }
  @media @xsmall {
    margin-bottom: 60px;
  }
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    @media @small-up {
      position: absolute;
      top: 0;
      left: 50%;
      width: 1899px;
      height: 874px;
      transform: translateX(-50%);
    }
    @media @xsmall {
      margin-top: -2em;
    }
  }
  &__inner {
    max-width: 100%;
    width: 460px;
    margin: 0 auto;
    text-align: center;
    @media @small-up {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: auto;
      padding-top: 13rem;
    }
  }
  &__title {
    margin-bottom: 3rem;
    font-weight: @bold-font-weight;
    color: @primary;
    font-size: 2.6rem;
    letter-spacing: .18em;
    text-indent: 0.18em;
    line-height: 1.6;
    @media @xsmall {
      font-size: 2rem;
      letter-spacing: .1em;
    }
  }
  &__text {
    @media @xsmall {
      max-width: 100%;
      width: 345px;
      padding: 0 2rem;
      margin: 0 auto;
    }
    p {
      margin-top: 0;
      font-size: 1.3rem !important;
      font-weight: @bold-font-weight;
      letter-spacing: .14em;
      line-height: 2.4;
      @media @small-up {
        text-indent: 0.14em;
      }
      @media @xsmall {
        letter-spacing: 0;
        text-align: left;
      }
    }
  }
}

.service-item {
  position: relative;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  @media @small-up {
    height: 280px;
  }
  & + & {
    margin-top: 4.4rem;
  }
  a&:hover, a&:focus{
    text-decoration: none;
  }
  &__row {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-shadow: 0 10px 25px 0 rgba(@black, 5%);
    @media @xsmall {
      flex-direction: column;
    }
    &:after {
      @arrow: escape('<svg width="31.41" height="16.41" viewBox="0 0 31.41 16.41" xmlns="http://www.w3.org/2000/svg"><g fill="@{secondary}"><path d="m23.21 16.41-1.42-1.41 6.8-6.79-6.8-6.8 1.42-1.41 8.2 8.21z"/><path d="m0 7.21h30v2h-30z"/></g></svg>');
      content: '';
      position: absolute;
      right: 10px;
      bottom: 24px;
      display: inline-block;
      width: 31px;
      height: 16px;
      margin: auto;
      background: right 15px center  / 31px 16px no-repeat;
      background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow}');
      transition: all @transition-fast ease-in-out;
      @media @xsmall {
        right: 5px;
        bottom: 18px;
      }
    }
  }

  &:hover &__img-cover,&:focus &__img-cover{
    transform: scale( 1.1 );
  }
  &__img {
    position: relative;
    flex-shrink: 0;
    max-width: 100%;
    width: 100%;
    margin: 0;
    overflow: hidden;
    z-index: 0;
    @media @small-up {
      width: 420px;
    }
  }
  &__img-cover{
    padding-top: 66.67%;
    width: 100%;
    background: center / cover no-repeat;
    transition: all @transition-fast ease-in-out;
    img{
      position: absolute;
      top: 0; left: 0;
      display: block;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;
    }
  }
  &__body {
    width: 100%;
    padding: 3rem 2.8rem 3.2rem;
    background: @white;
    @media @small-up {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 3rem 3.6rem 3rem 4.7rem;
    }
    .subject {
      .c-title-underline-left(@dark);
      margin-bottom: 2.8rem;
      font-size: 2.2rem;
      letter-spacing: .16em;
      @media @xsmall {
        margin-bottom: 1.8rem;
        font-size: 1.7rem;
      }
      span:after {
        @media @xsmall {
          width: 25px;
        }
      }
    }
    .desc {
      .c-text-normal;
      display: block;
      width: 100%;
      color: @dark;
    }
  }
}

/*詳細 記事テンプレ*/
.editor-template {
  //セクションタイトル
  h4.service-section-title {
    .c-title-underline-center(@dark);
    display: block;
    margin-bottom: 5.2rem !important;
    padding: 0 0 2rem !important;
    font-weight: @bold-font-weight !important;
    font-size: 2.2rem !important;
  }
}

//記事ヘッダー
.service-detail-head {
  font-feature-settings: "palt";
  margin: 2.8rem calc(-50vw + 50%) 4.8rem;
  @media @small-up {
    margin: 9.5rem calc(-50vw + 50%) 8.6rem;
  }
  &__row {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 1080px;
    margin: 0 auto;
    padding: 0 2rem;
    @media @small-up {
      flex-direction: row-reverse;
    }
    @media @xsmall {
      padding: 0 28px 48px;
    }
  }
  &__img {
    position: relative;
    flex-shrink: 0;
    width: 500px;
    @media @xsmall {
      width: 100%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__img-cover {
    padding-top: 66.4%;
  }
  &__body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    @media @small-up {
      max-width: 100%;
      width: 495px;
      margin-left: auto;
      padding-left: 2rem;
    }
    @media @xsmall {
      padding-bottom: 34px;
    }
    .desc {
      display: block;
      width: 100%;
      font-size: 1.3rem;
      letter-spacing: .1em;
      line-height: 1.8;
    }
  }
  &__title {
    position: relative;
    margin-bottom: 2.9rem;
    padding-bottom: 2.4rem;
    font-weight: @bold-font-weight;
    font-size: 2.6rem;
    letter-spacing: .18em;
    @media @xsmall {
      font-size: 2rem;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 32px;
      height: 5px;
      background: @secondary;
    }
  }
}

//記事フッターナビ
.service-detail-nav {
  max-width: 1000px;
  margin: 8rem auto 6.6rem;
  @media @small-up {
    margin: 11rem auto 11.5rem;
  }
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media @xsmall {
      margin: 0 calc(-50vw + 50%);
      border-top: 1px solid #C5D4FF;
    }
    li {
      @media @small-up {
        min-width: 84px;
        margin: 0 1rem 1rem;
      }
      @media @xsmall {
        flex-grow: 1;
        width: 50%;
        margin: 0;
      }
      &:last-child {
        @media @small-up {
          margin-bottom: 1rem;
        }
      }
      &:nth-child( 2n + 1 ) a {
        @media @xsmall {
          border-left-width: 0;
        }
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: .8em 1.2em;
        border: 1px solid #C5D4FF;
        color: @dark;
        letter-spacing: .1em;
        text-indent: 0.1em;
        font-weight: @bold-font-weight;
        text-align: center;
        transition-property: color, background-color;
        transition: @transition-fast ease-in-out;
        @media @xsmall {
          border-width: 0 0 1px 1px;
        }
        &:hover, &:focus, &.current {
          background: @primary;
          border-color: @primary;
          text-decoration: none;
          color: @white;
        }
      }
    }
  }
}


//お悩み解決します
.service-detail-solution {
  background: #EAF0FF;
  font-feature-settings: "palt";
  margin: 0 calc(-50vw + 50%);
  @media @small-up {
    margin: 0 calc(-50vw + 50%) 11.6rem;
  }
  .inner {
    max-width: 100%;
    width: 1000px;
    margin: 0 auto;
    padding: 7rem 2rem 6.5rem;
    @media @xsmall {
      padding: 4.7rem 2.8rem 5rem;
    }
  }
  .checkList {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(#88A4EE, 30%);
    .title {
      margin-bottom: 4rem;
      color: @dark !important;
      font-weight: @bold-font-weight;
      font-size: 2.4rem;
      letter-spacing: .16em;
      text-align: center;
      @media @xsmall {
        margin-bottom: 3rem;
        font-size: 2rem;
      }
    }
    ul {
      display: flex;
      margin: 0 auto;
      list-style: none !important;
      @media @small-up {
        flex-wrap: wrap;
      }
      @media @xsmall {
        max-width: 500px;
        padding: 0 14px;
        flex-direction: column;
      }
      li {
        display: flex;
        margin: 0 0 2rem;
        font-weight: @bold-font-weight;
        font-size: 1.3rem;
        line-height: 1.5;
        letter-spacing: .11em;
        @media @small-up {
          width: 33.333%;
          padding: 0 1.7rem;
        }
        @media @xsmall {
          margin: 0 0 1.5rem;
        }
        &:before {
          content: '';
          flex-shrink: 0;
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 7px;
          background: url('/asset/img/service/icon_check@2x.png') 50%~'/' 100% auto no-repeat;
          @media @xsmall {
            margin-right: 1em;
          }
        }
      }
    }
  }
  .targetList {
    max-width: 800px;
    margin: 0 auto;
    padding: 26px 0 0;
    color: @dark;
    text-align: center;
    @media @xsmall {
      padding: 28px 18px 0;
    }
    .title {
      font-weight: @bold-font-weight;
      font-size: 1.5rem;
      letter-spacing: .18em;
      text-indent: 0.18em;
    }
    ul {
      margin: 1.2rem auto;
      list-style: none;
      text-align: left;
      @media @small-up {
        text-align: center;
      }
      li {
        display: inline-block;
        position: relative;
        margin: 0 .5em 8px .5em;
        padding-left: calc(7px + 7px);
        font-size: 1.3rem;
        color: inherit;
        letter-spacing: .11em;
        @media @xsmall {
          display: block;
          margin: 0 0 14px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0.3em;
          left: 0;
          width: 10px;
          height: 10px;
          border-radius: 100em;
          background: @secondary;
          margin: auto;
        }
      }
    }

  }
}

//特徴・強み
.service-detail-feature {
  font-feature-settings: "palt";
  margin: 7rem auto;
  @media @small-up {
    margin: 11rem auto;
  }

  ol {
    counter-reset: number;
    max-width: 800px;
    margin: 0 auto;
    list-style: none;
    li {
      counter-increment: number 1;
      display: flex;
      align-items: center;
      margin: 0 0 6.2rem;
      @media @xsmall {
        flex-direction: column;
        margin: 0 0 4rem;
      }
      .img {
        width: 100%;
        min-height: .1%;
        @media @small-up {
          flex-shrink: 0;
          max-width: 100%;
          width: 330px;
          height: auto;
        }
        img {
          max-width: 100%;
          width: 100%;
          height: auto !important;
        }
      }
      .body {
        width: 100%;
        min-height: .1%;
        letter-spacing: .18em;
        text-align: left;
        @media @small-up {
          width: 100%;
          margin-left: 36px;
        }
        @media @xsmall {
          margin-top: 20px;
        }
        p {
          line-height: 1.7;
          letter-spacing: .1em;
          &:first-child {
            margin-top: 0;
          }
        }
        a {
          color: @primary;
          text-decoration: underline;
        }
      }
    }
    h5.item-title {
      display: flex;
      align-items: flex-start;
      font-weight: @bold-font-weight !important;
      font-size: 1.8rem !important;
      letter-spacing: .15em;
      line-height: 1.6 !important;
      color: @primary !important;
      @media @small-up {
        font-size: 2.2rem !important;
      }
      &:before {
        content: counter(number, decimal-leading-zero);
        display: block;
        height: 100%;
        margin-right: 15px;
        font-family: 'Cantarell','Roboto', @sans-serif-font-family;
        color: @primary;
        font-size: 3.2rem;
        font-weight: 300;
        letter-spacing: .06em;
        line-height: 1;
        @media @small-up {
          font-size: 4.2rem;
        }
      }
    }
  }
}

.service-detail-body {
  margin: 7rem auto;
  @media @small-up {
    margin: 11rem auto;
  }
}

//テーブルスタイル
.service-table {
  max-width: 100%;
  width: 720px;
  margin: 0 auto;
  @media @xsmall {
    width: 100%;
  }
  tr:nth-child(odd) {
    th {
      background: #EAF0FF;
    }
  }
  tr:nth-child(even) {
    th {
      background: #F9FAFF;
    }
  }

  th,td {
    border-color: #EFEFEF;
  }
  th {
    font-weight: @bold-font-weight;
    color: @dark;
    text-align: center;
    font-size: 1.4rem;
    letter-spacing: .1em;
    @media @xsmall {
      width: 7em;
    }
  }
  td {
    color: @dark;
    ul {
      list-style: none;
      @media @small-up {
        column-count: 2;
      }
      li {
        margin: 0 0 .5em;
        text-align: left;
        font-size: 1.3rem;
        letter-spacing: .15em;
        color: @dark;
      }
    }
  }
}

//流れ
.service-process {
  max-width: 960px;
  margin: 7rem auto 3rem;
  @media @small-up {
    margin: 11rem auto 8rem;
  }
  ol {
    counter-reset: processNum;
    list-style: none;
    @media @xsmall {
      margin: 0 calc(-50vw + 50%);
    }
  }
  li:nth-child(odd) {
    background: #EAF0FF;
  }
  li {
    counter-increment: processNum;
    margin: 0 !important;
    padding: 28px 28px;
    @media @small-up {
      display: flex;
      padding: 22px 40px;
    }
    &:before {
      @media @small-up {
        content: counter(processNum, decimal-leading-zero);
        margin-right: 3rem;
        font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
        font-weight: normal;
        color: @primary;
        font-size: 2.4rem;
        letter-spacing: .04em;
      }
    }
    .inner {
      width: 100%;
      @media @xsmall {
        min-height: 100px;
      }
    }
    strong {
      display: block;
      width: 100%;
      font-size: 1.8rem;
      letter-spacing: .1em;
      @media @xsmall {
        display: flex;
        align-items: center;
        color: @dark;
      }
      &:before {
        @media @xsmall {
          content: counter(processNum, decimal-leading-zero);
          margin-right: 1rem;
          font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
          font-weight: normal;
          color: @primary;
          font-size: 2.5rem;
          letter-spacing: .04em;
        }
      }
    }
    p {
      .c-text-normal();
      margin: .8rem 0 0 !important;
      letter-spacing: .1em;
    }
  }
}

