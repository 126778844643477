.c-text-normal{
  letter-spacing: 0.1em;
  line-height: 1.84;
  font-size: 1.3rem;
  &:last-child{
    margin-bottom: 0;
  }
  small{
    font-size: 0.8572em;
  }
}