
/*
 * Button
 */


// Button Grey
@button-grey-color: @white;
@button-grey-background: @grey;
@button-grey-border: none;
@button-grey-hover-color: @button-grey-color;
@button-grey-hover-background: darken(@button-grey-background, 10%);
@button-grey-hover-border: none;

// Button Border white
@button-border-white-color: @white;
@button-border-white-background: transparent;
@button-border-white-border: 1px solid @button-border-white-color;
@button-border-white-hover-color: @white;
@button-border-white-hover-background: @primary;
@button-border-white-hover-border: 1px solid @primary;


// Button Border white
.button-border-white,
a.button-border-white {
  .button-theme(
    @button-border-white-color,
    @button-border-white-background,
    @button-border-white-border,
    @button-border-white-hover-color,
    @button-border-white-hover-background,
    @button-border-white-hover-border
  );
}

// Button grey
a.button-grey,
.button-grey {
  .button-theme(
    @button-grey-color,
    @button-grey-background,
    @button-grey-border,
    @button-grey-hover-color,
    @button-grey-hover-background,
    @button-grey-hover-border
  );
}

.button-primary,
a.button-primary {
  .button-theme(
    @white, // color
    @primary, // background
    none, // border
    @white, // hover-color
    darken(@primary, 10%), // hover-background
    none // hover-border
  );
}

.button-secondary,
a.button-secondary {
  .button-theme(
    @white, // color
    @secondary, // background
    none, // border
    @white, // hover-color
    darken(@secondary, 15%), // hover-background
    none // hover-border
  );
}

.c-btn-icon-right{
  @arrow: escape('<svg height="5.84" viewBox="0 0 18.9 5.84" width="18.9" xmlns="http://www.w3.org/2000/svg"><path d="m0 5.34h17.68l-5.09-4.98" fill="none" stroke="@{body-color}" stroke-miterlimit="10"/></svg>');
  @arrow-on: escape('<svg height="5.84" viewBox="0 0 18.9 5.84" width="18.9" xmlns="http://www.w3.org/2000/svg"><path d="m0 5.34h17.68l-5.09-4.98" fill="none" stroke="@{white}" stroke-miterlimit="10"/></svg>');
  &:after{
    display: inline-block;
    margin-left: .5em;
    content: '';
    .size(1.4em,0.5em);
    background: center / cover no-repeat;
    background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow}');
  }
  &:hover, &:focus{
    &:after{
      background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow-on}');
    }
  }
}

.c-btn-left-line {
  position: relative;
  width: 210px;
  font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
  font-size: 1.3rem;
  letter-spacing: .1em;
  z-index: 0;
  &:before, &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    display: block;
    height: 2px;
    z-index: 0;
  }
  &:before {
    width: 23px;
    background: @white;
  }
  &:after {
    width: 5px;
    background: @secondary;
    z-index: 1;
    transition: left .3s ease-in-out;
  }

  &:hover, &:focus {
    &:after {
      left: 18px;
    }
  }
}