
nav#gmenu.is-default{ display: none}

header#header {
  @media @xsmall {
    padding-left: 1.5rem;
    box-shadow: none;
    height: 74px;
  }
}

.p-header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "palt";
  @media @small-up {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media @large-up {
    padding-left: 9.6rem;
    padding-right: 3.5rem;
  }
  @media @xsmall {
    justify-content: center;
  }

  body:not(.is--home) & {
    @media @small-up {
      align-items: flex-start;
      height: 17.5rem;
    }
  }

  &__logo{
    .hoverOpacity;
    margin-right: 2rem;
    margin-bottom: 0;
    @media @xsmall {
      margin-right: auto;
    }
    picture {
      @media @xsmall {
        display: flex;
      }
    }
    img {
      max-width: 100%;
    }
  }

  body:not(.is--home) &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img.is-home {
      display: none !important;
    }
  }

  // rwd_001/headerからコピーして編集
  > .drawerNav-btn{
    position: absolute;
    top: 50%; right: 7px;
    transform: translateY( -50% );
    border-width: 0;
    padding: 0.25em;
    font-size: 2.8rem;
    height: auto;
    background-color: transparent;
    transition: all @transition-fast ease-in-out;
    z-index: 0;
    @media @small-up {
      display: none;
    }
  }
}

// rwd_001/headerからコピーして編集
.p-gnav{
  display: flex;
  justify-content: center;
  overflow: auto;
  &.is-open{
    @media @xsmall {
      transform: translateX( 0 );
    }
  }
  &__list{
    @media @small-up {
      display: flex;
    }
    @media @xsmall {
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 0 2rem;
      padding: 0;
      width: 100%;
    }
  }
  &__item {
    margin: 0;

    >a {
      display: block;
      color: @dark;
      font-weight: @bold-font-weight;
      letter-spacing: 0.16em;
      text-indent: 0.16em;
      font-size: 1.4rem;
      transition: all @transition-fast ease-in-out;
      &:hover,
      &:focus {
        text-decoration: none;
      }

      i.fa, .icon {
        margin-left: 0.4em;
        letter-spacing: 0;
      }

      @media @small-up {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 110px;
        padding: 0.5em 1.2em;
        font-size: 1.4rem;
        letter-spacing: 0;
        text-indent: 0;
        &:hover,
        &:focus,
        &.current {
          span:before {
            opacity: 1;
            z-index: 1;
            left: calc(100% - 9px);
          }
          span:after {
            opacity: 1;
            width: 100%;
          }
        }
      }
      @media (min-width: 1281px) {
        padding: 0.5em 1.5em;
        font-size: 1.3rem;
      }

    }

    >a span {
      @media (min-width: 1281px) {
        letter-spacing: 0.16em;
        text-indent: 0.16em;
      }
    }

    @media @small-up {
      text-align: center;

      >a span {
        display: inline-block;
        padding: 0.7em 0;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          width: 9px;
          height: 2px;
          background-color: @secondary;
          opacity: 0;
          transition-property: opacity, left;
          transition-duration: 0s, @transition-fast;
          transition-duration: @transition-fast, @transition-slow;
          transition-timing-function: ease-in-out;

        }

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          width: 100%;
          height: 2px;
          transform: translateX(-50%);
          background-color: @primary;
          opacity: 0;
          transition: all @transition-fast ease-in-out;
        }
      }

    }

    @media @xsmall {
      position: relative;
      flex-grow: 0;
      border-width: 0;
      padding: 0;
      border: solid tint(@black, 90%);
      border-width: 1px 0;

      >a {
        padding: 1em;
        padding-left: 1.6em;
      }

      >a:after {
        content: '';
        display: block;
        position: absolute;
        transition: width @transition-medium ease-in-out;
        content: "\f105";
        font-family: fontAwesome;
        width: 1.6em;
        top: 0.8em;
        left: 0;
        font-weight: @medium-font-weight;
        text-align: center;
      }

      &+li {
        border-top-width: 0;
      }
    }

    &__indicator {
      @media @small-up {
        display: none;
      }

      @media @xsmall {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        width: 50px;
        height: 50px;
        z-index: 10;
        border-width: 0 !important;
        background-color: transparent !important;
      }

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        width: 10px;
        height: 1px;
        background-color: @secondary;
        transition: transform @transition-fast ease-in-out;
      }

      &:after {
        transform: rotate(-90deg);
      }

      &.is-active {
        &:before {
          transform: rotate(180deg);
        }

        &:after {
          transform: rotate(0deg);
        }
      }
    }

    &__item:hover &__subnav,
    &__item.current &__subnav {
      opacity: 1;
      transform: scale(1);
      z-index: 10;
    }
  }
  
  // プライバシーポリシーやサイトマップ（SPのみ表示）
  &__sublist{
    margin: 1.5rem -0.6em;
    white-space: nowrap;
  }
  &__subitem{
    position: relative;
    display: inline-block;
    padding:  0.2em 0.6em;
    margin: 0;
    font-size: 1.2rem;
    &:before{
      position: absolute;
      top: 50%; left: 0;
      transform: translate( -50%, -50% );
      content: '';
      height: 1em; width: 1px;
      background-color: tint( @body-color, 30%);
      display: none;
    }
    & + li:before{
      display: block;
    }
    a{
      color: tint( @body-color, 30% );
    }
  }
  &__copyright{
    color: tint( @body-color, 30% );
    font-size: 1.2rem;
  }
}
.p-gnav{
  @media @xsmall {
    justify-content: flex-start;
    position: fixed;
    top: 0; left: 100%;
    width: ~"calc( 100vw - 54px )";
    height: 100%;
    transition: all @transition-fast ease-in-out;
    background-color: @white;
    box-shadow: 0 0 6px fade( @black, 20%);
    z-index: 100;
    background-color: @white;
    opacity: 0;
  }
  &.is-open{
    @media @xsmall {
      opacity: 1;
      transform: translateX( -100% );
    }
  }
  &__inner{
    @media @small-up {
      display: flex;
      align-items: center;
    }
    @media @xsmall {
      position: relative;
      height: 100%;
      width: 100%;
      padding: 60px 22px 22px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  &__button-inquiry{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.5rem;
    border-color: @dark;
    color: @dark;
    font-weight: @bold-font-weight;
    font-size: 1.3rem;
    letter-spacing: .1em;
    @media @xsmall {
      max-width: 100%;
      padding: .5em .5em;
    }
    @media @small-up {
      margin-left: 3.5rem;
    }
    @media @medium-up {
      margin-left: 4.5rem;
    }
    &:hover:not(:disabled), &:focus:not(:disabled), &.current {
      background-color: @secondary;
      border-color: @secondary !important;
      color: @white !important;
      text-decoration: none;
      .icon {
        fill: @white;
      }
    }
    .icon {
      fill: @dark;
      margin-right: 1rem;
      transition: @transition-fast ease-in-out;
    }
  }
  .drawerNav-btn.is-close{
    position: absolute;
    top: 0; right: 0;
    border-width: 0;
    width: 60px; height: 60px;
    padding: 10px;
    z-index: 1;
    background-color: fade( @white, 50% );
    transform: translateY( 0 );
    &:hover,&:focus{
      border-width: 0;
    }
    @media @small-up {
      display: none;
    }
    span{
      position: absolute;
      top: 50%; left: 50%;
      height: 2px; width: 20px;
      background-color: @body-color;
      &:nth-child(1){
        transform: translate( -50%, -50%) rotate( 45deg );
      }
      &:nth-child(2){
        transform: translate( -50%, -50%) rotate( -45deg );
      }
    }
  }

}