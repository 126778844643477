/*
 * Title
 */

 .c-title-underline-left(@theme: @white) {
  position: relative;
  padding-bottom: 2rem;
  font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
  font-size: 3.8rem;
  color: @theme;
  font-weight: @bold-font-weight;
  letter-spacing: .094em;
  span:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 32px;
    height: 5px;
    background: @secondary;
  }
 }

 .c-title-underline-center(@theme: @white) {
  position: relative;
  padding-bottom: 2rem;
  font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
  font-size: 3.8rem;
  color: @theme;
  font-weight: @bold-font-weight;
  letter-spacing: .094em;
  text-indent: 0.094em;
  text-align: center;
  span:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 32px;
    height: 5px;
    margin: auto;
    background: @secondary;
  }
 }