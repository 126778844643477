.is--company {
  // 1カラム化
  .hybs-one-column(100%);

  .detailfooter {
    display: none;
  }
}

.company-mission {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 3.2rem auto 7.5rem;
  font-feature-settings: "palt";
  @media @small-up {
    flex-direction: row-reverse;
    max-width: 1365px;
    margin: 3rem auto 16.5rem;
  }

  &__img {
    position: relative;
    z-index: 1;
    max-width: 100%;
    @media @small-up {
      width: 720px;
    }
    @media @xsmall {
      margin-top: -54px;
      padding: 0 2.8rem;
    }
    img {
      max-width: 100%;
    }
    .name {
      margin-top: 1rem;
      text-align: right;
      @media @small-up {
        margin-top: 3rem;
        padding-right: calc( 80px + 40px );
      }
      small {
        margin-right: 0.5em;
        font-weight: @bold-font-weight;
        font-size: 1.2rem;
        letter-spacing: .14em;
        @media @small-up {
          margin-right: 1.8rem;
          font-size: 1.6rem;
        }
      }
      span {
        font-weight: @bold-font-weight;
        font-size: 1.7rem;
        letter-spacing: .16em;
        @media @small-up {
          font-size: 2.9rem;
        }
      }
    }
  }
  &__title {
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
    font-weight: @bold-font-weight;
    font-size: 5.4rem;
    color: @primary;
    letter-spacing: .04em;
    line-height: 1;
    text-align: center;
    z-index: 2;
    @media @small-up {
      top: -3.7rem;
      left: 4.8rem;
      font-size: 7.8rem;
    }
    @media @xsmall {
      right: 0;
      margin: auto;
      transform: translateY(-50%);
    }
  }

  &__body {
    max-width: 100%;
    width: 100%;
    padding: 7.5rem 3rem 11rem;
    background : linear-gradient(-40deg, rgba(@dark, 15%) 5%, rgba(@primary, 15%) 40%, rgba(#3da1d9, 15%) 75%, rgba(#9bd0c9, 15%) 100%);
    @media @small-up {
      position: relative;
      width: 720px;
      margin-top: 13.5rem;
      margin-left: -80px;
      padding: 12rem 10rem 11rem 17rem;
    }
  }

  &__text {
    font-weight: @bold-font-weight;
    font-size: 2rem;
    letter-spacing: .18em;
    line-height: 1.9;
    @media @small-up {
      max-width: 100%;
      width: 300px;
    }
    @media @xsmall {
      font-size: 1.7rem;
      text-align: center;
    }
  }
}

.company-info {
  margin-bottom: 6rem;
  @media @small-up {
    margin-bottom: 9.5rem;
  }
  &__title {
    .c-title-underline-center(@dark);
    font-size: 2.2rem;
    margin-bottom: 5rem;
  }
  &__tableWrap {
    margin: 0 auto;
    @media @small-up {
      width: 960px;
    }
    @media @xsmall {
      max-width: 550px;
      padding: 0 25px;
    }
    @media (max-width: 499px) {
      max-width: 360px;
    }
  }
  &__table {
    border: none;
    tr:last-child {
      th,td {
        border-width: 0;
      }
    }
    th, td {
      padding-top: 1em;
      padding-bottom: 1em;
      font-size: 1.4rem;
      border: solid #E5E8EC;
      border-width: 0 0 1px;
      letter-spacing: .1em;
      @media @xsmall {
        padding-top: 1.2em;
        padding-bottom: 1.2em;
      }
    }
    th {
      min-width: 12em;
      background: transparent;
      color: @dark;
      font-weight: @bold-font-weight;
      @media @xsmall {
        min-width: 5.5em;
        padding: 0;
        font-size: 1.3rem;
      }
    }
    td {
      text-align: left;
      line-height: 1.8;
      @media @xsmall {
        font-size: 1.3rem;
      }
      .visible-xsmall {
        @media @xsmall {
          display: inline !important;
        }
      }
    }
  }
  &__map {
    position: relative;
    margin: 1em auto;
    text-align: center;
    &-inner {
      position: relative;
      overflow: hidden;
      height: 320px;
      @media @small-up {
        height: 400px;
      }
    }
    iframe {
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
      top: -200px;
      bottom: -200px;
      width: 100%;
      margin: auto;
      height: calc(100% + 400px);
    }
    .btnwrap {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      .button {
        display: flex;
        align-items: center;
        width: 194px;
        height: 5rem;
        padding: 0.5em 1em;
        font-weight: @bold-font-weight;
        font-size: 1.3rem;
        letter-spacing: .15em;
        i.fa {
          margin-right: .7em;
          font-size: 1.8rem;
        }
      }
    }
  }
}
