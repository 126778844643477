// テンプレートURL
@template-directory: '//secure.websapo.jp/_site_template/layout/rwd_001';

//import turret override
@import 'turret/turret';

//import components
@import '_components';

//import extend mixins
@import (reference) '_mixins_extend';

//webfont読み込み
@fontfile_path: '../fonts/';
@import 'fonts/font-awesome';
@import 'fonts/Roboto';
@import url('https://fonts.googleapis.com/css2?family=Cantarell:wght@700&family=Noto+Serif+JP:wght@400;700&display=swap');

// デフォルトをカスタム用に上書きするスタイル(基本変更しない)
@import '_common';

// CMSベーシックパーツインポート：サイト特有のカスタムも以下ファイル内に記載する
@import 'elements/toolbar';
@import 'elements/breadcrumbs';
@import 'elements/paginations';
@import 'elements/block';

// 各セクションパーツインポート：サイト特有のカスタムも以下ファイル内に記載する
@import 'elements/header';
@import 'elements/content-header';
@import 'elements/side';
@import 'elements/footer';

// サイト特有の全体用カスタマイズを以下に記載
.toolbar { display: none; }

#allbox {
  @media @xsmall {
    padding-top: 74px;
  }
}

.is--contact {
  .hybs-one-column(800px, 8px);
  #container {
    margin-bottom: 8rem;
    @media @small-up {
      margin-bottom: 10rem;
    }
  }

  .element_mailform button[type="submit"] {
    background: @primary;
    &:hover, &:focus {
      background: darken(@primary, 10%);
    }
  }
}

.is--news {
  #container {
    margin-bottom: 6rem;
  }
  #main > .element_detail > .contents {
    @media @xsmall {
      padding-left: 28px;
      padding-right: 28px;
    }
  }
  #side td img {
    width: 88px !important;
    height: auto !important;
  }
}

.is--news.is--list {
  #main>.element_list {
    .title {
      display: none;
    }
    .contents.list_imgflo {
      margin-top: 0;
    }
  }
}

// ページごとのスタイルを読み込み
@import 'page/home.less';
@import 'page/service.less';
@import 'page/company.less';