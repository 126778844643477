.is--home {
  // 1カラム化
  .hybs-one-column(100%);

  #allbox {
    padding-top: 0;
  }

  .p-header {
    position: absolute;
    left: 0;
    z-index: 1;

    &.is-transparent .p-header__logo {
      img.is-lower {
        display: none;
      }
    }

    & .p-header__logo {
      a {
        display: flex;
        align-items: center;
      }
      img {
        width: 151px;
        padding: 0;
      }
      img.is-home {
        @media @xsmall {
          display: none;
        }
      }
      img.is-lower {
        @media @small-up {
          display: none;
        }
      }
    }
    @media @xsmall {
      background-color: @white;

      &.is-transparent {
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: none !important;
        background-color: transparent !important;

        .drawerNav-btn .menu-icon { fill: @white; }

        & .p-header__logo {
          width: 100%;
          padding: 2.5rem 0 2.5rem;
          img.is-home {
            display: block;
            width: 151px;
          }
        }
      }
    }
  }

  .p-gnav__item {
    a {
      @media @small-up {
        color: @white;
      }
      &:hover, &:focus, &.current {
        @media @small-up {
          color: @white;
        }
      }
    }
  }

  .p-gnav__button-inquiry {
    @media @small-up {
      color: @white;
      border-color: @white;
    }
    .icon {
      @media @small-up {
        fill: @white;
      }
    }
    &:hover, &:focus, &.current {
      @media @small-up {
        color: @secondary;
        border-color: @secondary;
      }
    }
  }

  .p-footer {
    background-color: rgba(@black, 80%);
  }
}

//メインビジュアル
.mainvisual {
  position: relative;
  overflow: hidden;
  height: 640px;
  font-feature-settings: "palt";
  background-color: @black;
  z-index: 0;
  transition: background-color @transition-fast ease-in-out;

  @media @small-up {
    height: 768px;
  }

  &.is-active {
    background-color: transparent;
  }

  &__mov {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 768px;
    overflow: hidden;
    @media @xsmall {
      height: 640px;
    }
  }

  &__catch {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 auto;
    z-index: 1;
    @media @small-up {
      top: 0;
      bottom: 0;
      left: calc(50% + 80px);
    }
    @media @medium-up {
      left: calc(50% + 155px);
    }
    @media @xsmall {
      left: 0;
      right: 0;
      align-items: flex-end;
      bottom: 16.3rem;
    }
    &-text {
      margin: 0;
      color: @white;
      white-space: nowrap;
      font-size: 5.5rem;
      font-weight: @bold-font-weight;
      line-height: 1.58;
      letter-spacing: .18em;
      @media @xsmall {
        font-size: 3.9rem;
      }
    }
  }
  &__ticker-news {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .bg-blur {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    backdrop-filter: blur(10px);
    background-color: rgba(#00001E, 60%);
    @media @small-up {
      width: 3000px;
      height: 768px;
      clip-path: url(#cp);
    }
    @media (min-width: 3001px) {
      width: calc(100% + 40px);
      left: 0;
      right: 0;
      transform: none;
      background-size: 100% auto;
    }
    @media @xsmall {
      width: 900px;
      height: 640px;
      clip-path: url(#cp_sp);
    }
    @media all and (-ms-high-contrast:none){
      backdrop-filter: none;
      background: 50%~'/' 3000px auto no-repeat no-repeat;
      background-image: url('/asset/img/home/mainvisual_mask.png');
      @media @xsmall {
        background: 50%~'/' 900px auto no-repeat no-repeat;
        background-image: url('/asset/img/home/mainvisual_mask_sp@2x.png');
      }
    }
  }
}

.block-ticker {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px 26px 22px 23px;
  background-color: rgba(@primary, 50%);

  @media @small-up {
    max-width: 66vw;
    height: 7rem;
    padding: 8px 26px 8px 56px;
    border-width: 0 0 1px;
  }
  @media @xsmall {
    align-items: flex-start;
    min-height: 94px;
  }

  &__title {
    margin: 0 2em 0 0;
    font-family: 'Cantarell', @sans-serif-font-family;
    font-size: 1.8rem;
    color: @white;
    letter-spacing: 0.094em;
    font-weight: @regular-font-weight;
    @media @xsmall {
      display: inline-block;
      margin: 0 2em 0 0;
      font-size: 1.4rem;
    }
  }

  &__article {
    color: @white;
    font-size: 1.4rem;
    letter-spacing: 0.16em;
    @media @small-up {
      max-width: 60%;
    }

    .link {
      position: relative;
      display: flex;
      color: inherit;
      text-decoration: none;
      font-feature-settings: "palt";
      @media @xsmall {
        flex-wrap: wrap;
        font-size: 1.3rem;
        letter-spacing: .1em;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        color: @white;

        &:after {
          transform: translateX(10px);
        }
      }
    }

    .subject {
      .truncate();
      max-width: 100%;
      width: 100%;
      letter-spacing: .06em;
      @media @xsmall {
        display: block;
        white-space: normal;
        font-size: 1.3rem;
        letter-spacing: .08em;
      }
    }

    .cat, .date {
      display: inline-block;
      font-size: 1.2rem;
      @media @xsmall {
        margin-bottom: 10px;
      }
      @media @small-up {
        vertical-align: middle;
        line-height: 1.8;
      }
    }

    .cat {
      letter-spacing: .18em;
      @media @small-up {
        margin-right: 10px;
        white-space: nowrap;
      }
      @media @xsmall {
      }
    }
    .date {
      letter-spacing: .2em;
      @media @small-up {
        margin-right: 1.8vw;
      }
    }

  }
}

//FEATURE
.block-feature {
  background: @white;
  font-feature-settings: "palt";
  padding-bottom: 31.6rem;
  @media @xsmall {
    padding-bottom: 5rem;
  }
  &__row {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    z-index: 0;
    @media @xsmall {
      flex-direction: column;
    }
  }
  &__img {
    @media @small-up {
      position: absolute;
      top: 93px;
      right: calc(50vw - 68px);
      overflow: hidden;
    }
    @media @xsmall {
      margin-top: -72px;
      padding-right: 72px;
    }
    img {
      max-width: 100%;
    }
  }
  &__body {
    width: 100%;
    background: @primary;
    color: @white;
    @media @small-up {
      max-width: 66vw;
      min-height: 460px;
      padding-left: 13.3vw;
      padding-right: 2em;
    }
    &-inner {
      max-width: 100%;
      margin: 0 auto;
      @media @small-up {
        width: 500px;
        margin: 8rem 0 0;
      }
      @media @xsmall {
        width: 325px;
        padding: 5rem 2rem 11.6rem;
      }
    }
  }

  &__title {
    .c-title-underline-left;
    margin-bottom: 2.5rem;
    padding-bottom: 2rem;
  }

  &__text {
    font-size: 1.3rem;
    color: @white;
    font-weight: @bold-font-weight;
    line-height: 2.4;
    letter-spacing: .1em;
  }
  &__bg {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: -1;
    @media @xsmall {
      display: none;
    }
  }
}

//SERVICE
.block-service {
  padding-bottom: 21rem;
  background: @white;
  font-feature-settings: "palt";
  @media @small-up {
    padding-bottom: 15.7rem;
  }
  &__title {
    .c-title-underline-center(@primary);
    margin-bottom: 2.4rem;
    padding-bottom: 1.5rem;
  }
  &__lead {
    margin-bottom: 5rem;
    padding: 0 3rem;
    p {
      .c-text-normal;
    }
  }
  &__grid {
    margin: 0;
    @media @small-up {
      display: grid;
      grid-template-columns: 33.3% 16.6% 16.6% 33.3%;
      grid-template-rows: 510px 120px;
    }
  }

  &__btnwrap {
    margin-top: 4rem;
    text-align: center;
    .button {
      .c-btn-left-line;
    }
  }
}

.block-service-item {
  margin: 0;
  border: solid #EAF0FF;
  border-width: 1px 0 0;
  list-style: none;
  & + & {
    @media @small-up {
      border-width: 1px 0 0 1px;
    }
  }
  &:last-child {
    @media @xsmall {
      border-width: 1px 0 1px;
    }
  }

  >a {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    transition: background-color .2s ease-in-out;
    &:hover, &:focus {
      text-decoration: none;
      background: rgba(#EAF0FF, 30%);
      &:after {
        background-position: right center;
      }
    }
    &:after {
      @arrow: escape('<svg width="31.41" height="16.41" viewBox="0 0 31.41 16.41" xmlns="http://www.w3.org/2000/svg"><g fill="@{secondary}"><path d="m23.21 16.41-1.42-1.41 6.8-6.79-6.8-6.8 1.42-1.41 8.2 8.21z"/><path d="m0 7.21h30v2h-30z"/></g></svg>');
      content: '';
      position: absolute;
      right: 10px;
      bottom: 24px;
      display: inline-block;
      width: 31px;
      height: 16px;
      margin: auto;
      background: right 15px center  / 31px 16px no-repeat;
      background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow}');
      transition: all @transition-fast ease-in-out;
    }
  }

  .num {
    font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
    font-weight: @bold-font-weight;
    font-size: 3.6rem;
    color: @secondary;
    line-height: 1;
    @media @xsmall {
      font-size: 2.9rem;
    }
  }

  &__illust {
    padding-bottom: 3.7rem;
    @media @xsmall {
      max-width: 100%;
      width: 274px;
      margin: 0 auto;
      padding-bottom: 3.1rem;
    }
    img {
      max-width: 100%;
    }
  }

  &__title {
    margin: 0;
    font-weight: @bold-font-weight;
    color: @primary;
    font-size: 2.2rem;
    letter-spacing: .16em;
    text-align: center;
    @media @xsmall {
      font-size: 1.8rem;
      letter-spacing: .16em;
      text-indent: 0.16em;
      line-height: 1.5;
    }
  }
  &.is-item4 &__title,
  &.is-item5 &__title {
    @media @xsmall {
      margin: 0 4.2rem;
    }
  }

  &.is-item1, &.is-item2, &.is-item3 {
    @media @small-up {
      grid-row: 1;
    }
    >a {
      padding: 0 1em 5.6rem;
      @media @xsmall {
        padding: 7rem 2rem 4rem;
      }
    }

    .num {
      position: absolute;
      top: 33px;
      left: 35px;
      @media @xsmall {
        left: 2.5rem;
      }
    }
  }
  &.is-item1 {
    @media @small-up { grid-column: 1; }}
  &.is-item2 {
    @media @small-up { grid-column:  2/ span 2; }}
  &.is-item3 {
    @media @small-up { grid-column: 4; }}

  &.is-item4,
  &.is-item5 {
    grid-row: 2;
    @media @small-up {
      border-bottom-width: 1px;
    }
    >a {
      align-items: center;
      justify-content: center;
      flex-direction: row;
      @media @small-up {
        padding-left: 2rem;
        padding-right: 4.5rem;
      }
      @media @xsmall {
        padding: 4.4rem 2rem 4rem;
      }
      &:after {
        top: 0;
        bottom: 0;
      }
    }
    .num {
      margin-right: 2.5rem;
      @media @xsmall {
        position: absolute;
        top: 0; bottom: 0;
        left: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
  &.is-item4 {
    @media @small-up {
      grid-column: 1 / span 2;
    }
  }
  &.is-item5 {
    @media @small-up {
      grid-column: 3 / span 2;
    }
  }

}

//COMPANY
.block-company {
  position: relative;
  font-feature-settings: "palt";
  @media @small-up {
    height: 462px;
  }
  @media @xsmall {
    padding-bottom: 68px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(@black, 60%);
    backdrop-filter: blur(15px);
  }
  &__row {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100%;
    width: 1140px;
    height: 100%;
    margin: 0 auto;
    z-index: 1;
    @media @small-up {
      flex-direction: row-reverse;
    }
  }
  &__img {
    @media @xsmall {
      margin-top: -136px;
      margin-left: auto;
      padding-left: 34px;
    }
    @media @small-up {
    }
    img {
      max-width: 100%;
    }
  }
  &__title {
    .c-title-underline-left;
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
  }
  &__body {
    @media @small-up {
      width: 380px;
    }
    @media @xsmall {
      width: 325px;
      margin: 0 auto;
      padding: 3.4rem 2rem 0;
    }
    p {
      .c-text-normal;
      font-weight: @bold-font-weight;
      line-height: 2.3;
      color: @white;
      @media @xsmall {
        letter-spacing: .05em;
      }
    }
  }
  &__btnwrap {
    margin-top: 3rem;
    .button {
      .c-btn-left-line;
    }
  }
}

//RECRUIT
.block-recruit {
  padding-bottom: 6.8rem;
  background: @white;
  font-feature-settings: "palt";
  @media @small-up {
    padding-top: 11.5rem;
    padding-bottom: 10.8rem;
  }
  @media @xsmall {
    padding-top: 5.6rem;
  }
  &__row {
    position: relative;
    z-index: 0;
  }
  &__img {
    @media @small-up {
      position: absolute;
      right: calc(50% - 117px );
      z-index: -1;
    }
    &:after {
      @media @small-up {
        content: '';
        position: absolute;
        bottom: 0;
        right: calc(50% - 117px);
        display: block;
        width: 100vw;
        height: 350px;
        background: #CDDBF3;
        z-index: -1;
      }
    }
    img {
      @media @xsmall {
        max-width: 100%;
      }
    }
  }
  &__title {
    margin-bottom: 3.5rem;
    font-family: 'Cantarell', 'Roboto', @sans-serif-font-family;
    font-size: 7.8rem;
    color: @primary;
    font-weight: @bold-font-weight;
    line-height: 1;
    letter-spacing: .074em;
    @media @xsmall {
      margin-bottom: 0;
      font-size: 6.4rem;
      text-align: center;
      transform: translateY(-44%);
      letter-spacing: 0;
    }
  }
  &__body-wrap {
    position: relative;
    max-width: 100%;
    width: 100%;
    @media @small-up {
      left: calc(50vw - 56px);
      max-width: 54vw;
      width: 630px;
      padding-top: 14rem;
    }
  }
  &__body {
    padding: 0 1rem;
    background: @white;
    @media @small-up {
      padding: 5rem 3rem 4.3rem;
      box-shadow: 0 10px 30px 0 rgba(@black, 5%);
    }
  }
  &__body-inner {
    max-width: 100%;
    width: 480px;
    margin: 0 auto;
    @media @xsmall {
      width: 325px;
      padding: 0 2rem;
    }
  }
  &__catch {
    font-size: 2.2rem;
    line-height: 1.5;
    letter-spacing: .1em;
    @media @small-up {
      font-size: 2.6rem;
      letter-spacing: .18em;
    }
  }
  &__text {
    .c-text-normal;
  }
  &__btnwrap {
    margin-top: 2.5rem;
    .button {
      .c-btn-left-line;
    }
  }
}

//NEWS
.block-news {
  position: relative;
  font-feature-settings: "palt";
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(@white, 90%);
    backdrop-filter: blur(15px);
  }
  &__inner {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    padding: 6rem 0 9.2rem;
    z-index: 1;
    @media @small-up {
      display: grid;
      grid-template-columns: 293px 764px;
      grid-template-rows: 100px auto;
      width: 1097px;
      padding: 12.4rem 2rem 14rem;
    }
    @media @xsmall {
      width: 325px;
    }
  }
  &__title {
    .c-title-underline-left(@primary);
    @media @small-up {
      grid-column: 1;
      grid-row: 1;
    }
    @media @xsmall {
      margin: 0 2rem 4.3rem;
    }
  }
  &__list{
    max-width: 100%;
    list-style: none;
    margin: 0 0 2rem;
    @media @small-up {
      grid-column: 2;
      grid-row: 1/ span 2;
      width: 100%;
      margin-bottom: 4rem;
    }
    @media @xsmall {
      width: 325px;
      margin: 0 auto;
    }
  }
  &-item{
    border: 1px solid rgba(@black, 20%);
    border-width: 1px 0;
    margin: 0;
    padding: 0;
    @media @xsmall {
      border-width: 1px 0 0;
    }
    &:last-child {
      @media @xsmall {
        border-width: 1px 0 1px;
      }
    }
    a{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: all @transition-fast ease-in-out;
      padding: 1em 2rem;
      color: @dark;
      @media @small-up {
        padding: 1em .5em;
      }
      @media @xsmall {
        flex-wrap: wrap;
      }
      &:hover,&:focus{
        text-decoration: none;
        color: @dark;
        .subject {
          text-decoration: underline;
        }
      }
    }

    .date-cate {
      display: flex;
      align-items: center;
    }
    .date{
      margin: 0 1em 0 0;
      font-family: 'Cantarell', 'Roboto',@sans-serif-font-family;
      font-size: 1.4rem;
      color: @primary;
      letter-spacing: 0.1em;
      min-width: 7.5em;
    }
    .cate{
      .truncate();
      display: inline-block;
      vertical-align: middle;
      min-width: 96px;
      max-width: 60%;
      min-height: 2.7rem;
      padding: 0.2em;
      background: @primary;
      font-size: 1.4rem;
      color: @white;
      letter-spacing: 0.1em;
      text-indent: 0.1em;
      text-align: center;
      line-height: 1.6;
      @media @small-up {
        margin-right: 1.3em;
      }
      @media @xsmall {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 80px;
        font-size: 1.1rem;
      }
    }
    .subject {
      letter-spacing: 0.1em;
      @media @xsmall {
        width: 100%;
        margin-top: 0.4em;
      }
    }
    .subject.is-new:after{
      content: 'NEW';
      display: inline-block;
      font-size: 0.857em;
      color: @warning;
      margin-left: 0.5em;
      letter-spacing: 0.06em;
    }
  }
  &-item + &-item{
    @media @small-up {
      border-top-width: 0;
    }
  }
  &__btnwrap {
    @media @small-up {
      grid-column: 1;
      grid-row: 2;
    }
    @media @xsmall {
      padding-top: 3rem;
      text-align: center;
    }
    .button {
      .c-btn-left-line;
    }
  }
}